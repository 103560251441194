<template>
  <div>
    <b-modal
      id="incomePcsSalesDetailsModal" 
      header-class="pb-2"
      size="xl"
      v-model="showModal">

      <template slot="modal-header">
         <b-col sm="12" class="d-flex pr-1">
            <div class="d-flex col-6 col-sm-6 text-left ml-2">
              <h4 id="modalLabel" class="text-primary mr-2">
                Выручка
              </h4>
               <income-title-edit 
                :title="sectionData ? sectionData.title : ''" 
                @updateTitle="updateTitle" /> 
            </div>
            <div class="col-3 col-sm-auto ml-auto text-right pl-0 ьк-5">
                <div class="btn-group btn-group-sm" role="group" aria-label="">
                  <button 
                    class="btn btn-outline-secondary"
                    :class="{'active': section == 'amount'}" 
                    type="button"
                    @click="section = 'amount'">
                    Кол-во
                  </button>
                  <button 
                    class="btn btn-outline-secondary"
                    :class="{'active': section == 'price'}" 
                    type="button"
                    @click="section = 'price'">
                    Цена
                  </button>
                  <button 
                    class="btn btn-outline-secondary"
                    :class="{'active': section == 'revenue'}" 
                    type="button"
                    @click="section = 'revenue'">
                    Итого
                  </button>
                </div>
                <button 
                  type="button" 
                  aria-label="Close" 
                  class="close align-top ml-4 pr-0"
                  @click.prevent="showModal = false">
                  ×
                </button>
              </div> 
         </b-col>           
      </template>

      <!-- AMOUNT SECTION -->
      <wizard-section v-if="section == 'amount'"
        :data="sectionData ? sectionData.units: []"
        unitsTitle="ед. продукции"
        :defaultMax="1000"
        :decimals="0"
        @data="updateData">
        <h5>Сколько единиц продукции планируется продать?</h5>
        <p class="font=proxima fs--1 mt-2">
          Как лучше всего определить "единицу", зависит от того, что вы продаете. Если вы предлагаете вещи, 
          просто введите количество (рубашки, компьютеры или что-то еще), которое планируется продать. 
          Для других предложений можно использовать единицы измерения для обозначения контрактов с фиксированной ценой 
          или коробок (поддонов) при продаже оптом.
          Указывайте то, что имеет смысл для вашего бизнеса.
        </p>
      </wizard-section>
      <!-- END AMOUNT SECTIN -->
      <!-- ----------------- -->

      <!-- PRICE SECTION -->
      <wizard-section v-if="section == 'price'"
        :data="sectionData ? sectionData.prices : []"
        unitsTitle="руб. / ед. продукции"
        :decimals="2"
        :defaultMax="1000"
        :yearsMedian="true"
        @data="updateData">
        <h5>По чем планируется продать?</h5>
        <p class="font=proxima fs--1 mt-2">
          Введите среднюю цену продажи (без учета налога с продаж) для каждой единицы 
          данного продукта или услуги. Также можно изменять цены с течением времени, при необходимости, 
          чтобы отразить сезонные изменения спроса, плановые повышения или акции (со скидками).
        </p>
      </wizard-section>
      <!-- END PRICE SECTIN -->
      <!-- ---------------- -->

      <!-- INCOME SECTION -->
      <wizard-section v-if="section == 'revenue'"
        :data="sectionData ? sectionData.values : []" 
        unitsTitle="рублей"
        :readonly="true"
        :defaultMax="1000"
        :decimals="2">
        <slot>
          <h5>Итоговый рузультат</h5>
          <p class="font=proxima fs--1 mt-2">
            Оцените результат внесенных данных. Посмотрите на динамику выручки, соответсвует ли она 
            вашим ожиданиям или нужно внести корректировки. <br>
            При необходимости вернитесь назад и измените цены или количество. 
          </p>
        </slot>
      </wizard-section>
      <!-- END INCOME SECTIN -->
      <!-- ----------------- -->

      <template v-slot:modal-footer>
        <div class="w-100">
          <div class="btn btn-outline-dark delete-btn ml-2 px-2 py-0 float-left"
            @click="deleteRevenue">
            <i class="far fa-trash-alt mr-2"></i>
            <span class="font-proxima font-weight-light">
              Удалить
            </span>
          </div>
          
          <b-button v-if="section != 'revenue'"
            variant="outline-primary"
            size="sm"
            class="float-right"
            @click="nextSection">
            Далее
            <i class="fas fa-arrow-right ml-2"></i>
          </b-button>
          <b-button 
            v-if="savingData"
            class="float-right"
            variant="outline-success"
            size="sm"
            disabled>
            <b-spinner small type="grow"></b-spinner>
            Сохраняем...
          </b-button>
          <b-button v-if="!savingData && section == 'revenue'"
            variant="outline-success"
            size="sm"
            class="float-right"
            @click="saveData">
            Готово!
          </b-button>
          <b-button v-if="section != 'amount'"
            variant="outline-secondary mr-2"
            size="sm"
            class="float-right"
            @click="prevSection">
            <i class="fas fa-arrow-left mr-2"></i>
            Назад
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import WizardSection from '../widgets/Modals/IncomeCreate/WizardSection'
import IncomeTitleEdit from '../widgets/Modals/IncomeCreate/IncomeTitleEdit'

export default {
  props: ['incomeTitle'],
  
  data: () => ({
    showModal: false,
    section: 'amount',
    titleEdit: false,
    savingData: false,
  }),

  components: {
    WizardSection,
    IncomeTitleEdit
  },
  
  computed: {
    sectionData() {
      return this.$store.getters.getRevenueToEdit
    }
  },
  
  methods: {
    updateTitle(value) {
      this.sectionData.title = value
    },

    updateData(newData) {
      const unitsSold = Object.values(this.sectionData.units)[newData.year][newData.index]
      const price = Object.values(this.sectionData.prices)[newData.year][newData.index] 
      var revenue = null
      
      revenue = parseFloat((unitsSold * price).toFixed(0))

      if (revenue > 0) {
        this.$set(this.sectionData.values[newData.year], [newData.index], revenue)
      } else {
        this.$set(this.sectionData.values[newData.year], [newData.index], null)
      }
    },

    nextSection() {
      if (this.section == 'amount') {
        this.section = 'price'
      } else {
         this.section = 'revenue'
      }
    },
    prevSection() {
      if (this.section == 'revenue') {
        this.section = 'price'
      } else {
         this.section = 'amount'
      }
    }, 

    async saveData() {
      this.savingData = true
      const payload = {
        path: this.sectionData.path,
        title: this.sectionData.title,
        type: this.sectionData.type,
        values: this.sectionData.values,
        units: this.sectionData.units,
        prices: this.sectionData.prices
      }

      await this.$store.dispatch('updateRevenue', payload)
      this.savingData = false
      this.showModal = false
    },

    deleteRevenue() {
      this.$bvModal.msgBoxConfirm('Вы действительно хотите удалить данный источник выручки?', {
        title: 'Подтвердите удаление',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Да, удалить',
        cancelTitle: 'Нет',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async () => {
        await this.$store.dispatch('deleteRevenue', this.sectionData)
        this.showModal = false
      })
      .catch(err => {
          // An error occurred
          window.console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.delete-btn {
  color: rgba(145, 145, 145, 0.2);
  border: none;
  span {
    display: none;
  }
  &:hover {
    color: #e63757;
    border-color: rgb(145, 145, 145) !important;
    span {
      display: inline;
    }
  }
}

</style>