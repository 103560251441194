<template>
   <div>
    <b-modal
      id="incomeCreateModal" 
      header-class="pb-2"
      hide-footer
      no-stacking
      @close="modalClear()">

      <template slot="modal-title">
        <div class="row mt-2">
            <div class="col text-left ml-2" style="min-width: 7rem">
              <h4 id="modalLabel" class="text-primary">
                Выручка
                <small class="text-500">основные данные</small>
              </h4>
            </div>              
          </div>
      </template>

      <b-row class="mt-2">
          <b-col sm="12">
            <b-form-group
            class="ml-2 mr-2" 
            label="Какое название использовать?"
            labele-for="incomeTitle" 
            :invalid-feedback="invalidFeedback"
            :valid-feedback="validFeedback"
            :state="titleState">
            <b-form-input
              id="incomeTitle"
              v-model="revenueTitle"
              :state="titleState">
            </b-form-input>
          </b-form-group>
          </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col sm="12">
          <b-form-group 
            label="Тип выручки" 
            class="ml-2 mr-2">
            <b-form-radio-group 
              id="radio-group-2" 
              v-model="selected" 
              name="radio-sub-component">
              <b-row>
                <b-col sm="6" class="pr-1 pb-2">
                  <div class="income-option"
                    :class="{'selected': selected == 'pcsSales'}"
                    @click="selected='pcsSales'">
                    <b-form-radio value="pcsSales">
                      <strong>Продажи по штукам</strong>
                    </b-form-radio>
                    <p class="income-title">
                      <small>
                        Лучше подходит для товаров, которые продаются поштучно
                      </small>
                    </p>
                  </div>
                </b-col>
                <b-col sm="6" class="pl-1 pb-2">
                  <div class="income-option"
                  :class="{'selected': selected == 'hrsSales'}"
                   @click="selected='hrsSales'">
                    <b-form-radio value="hrsSales">
                      <strong>Почасовая оплата</strong>
                    </b-form-radio>
                    <p class="income-title">
                      <small>
                        Лучше подходит для услуг с фиксированной ценой за час
                      </small>
                    </p>
                  </div>
                </b-col>
                <b-col sm="6" class="pr-1">
                  <div class="income-option"
                  :class="{'selected': selected == 'subscriptions'}"
                   @click="selected='subscriptions'">
                    <b-form-radio value="subscriptions">
                      <strong>Продажа подписок</strong> 
                    </b-form-radio>
                    <p class="income-title">
                      <small>
                        Лучше подходит для подписок, членства, приложений 
                        с периодическими платежами
                      </small>
                    </p>
                  </div>
                </b-col>
                <b-col sm="6" class="pl-1">
                  <div class="income-option"
                  :class="{'selected': selected == 'onlyIncome'}"
                  @click="selected='onlyIncome'">
                    <b-form-radio v-model="selected" value="onlyIncome">
                      <strong>Только выручка</strong> 
                    </b-form-radio>
                    <p class="income-title">
                      <small>
                      Если вам не требуется детализация, выберите этот пункт 
                      чтобы ввести только сумму
                      </small>
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
      
      <b-row >
        <b-col sm="12" class="d-flex">
          <b-button class="mt-3 mr-3 ml-2" @click.prevent="showModal = false">
            Отмена
          </b-button>
          <b-button 
            v-if="loadData"
            class="mt-3 mr-2"
            variant="primary"
            block 
            disabled>
            <b-spinner small type="grow"></b-spinner>
            Создаем...
          </b-button>
          <b-button 
            v-if="!loadData"
            class="mt-3 mr-2" 
            variant="primary" 
            block 
            @click="createRevenue">
            Создать!
          </b-button>
        </b-col>
      </b-row>
      
    </b-modal>

  </div>
</template>

<script>

export default {
  data: () => ({
    showModal: false,
    revenueTitle: '',
    loadData: false,
    selected: 'onlyIncome',
    revenueDocId: null
  }),

  computed: {
    titleState() {
      return this.revenueTitle.length >= 4 ? true : false
    },
    invalidFeedback() {
      if (this.revenueTitle.length >= 4) {
        return ''
      } else if (this.revenueTitle.length > 0) {
        return 'Название должно быть не менее 4-х символов'
      } else {
        return 'Пожалуйства, введине название...'
      }
    },
    validFeedback() {
      return this.state === true ? 'Отлично!' : ''
    },
  },

  methods: {
    async createRevenue() {
      this.loadData = true
      this.revenueDocId = null
      if (this.titleState) {
        const payload = {
          title: this.revenueTitle,
          type: this.selected
        }
        
        this.revenueDocId = await this.$store.dispatch('createRevenueDocument', payload)

        if (this.revenueDocId == null) {
          this.loadData = false
          return
        }

        switch (this.selected) {
          case 'onlyIncome':
            this.$bvModal.show('incomeOnlyIncomeDetailsModal');
            break;
          case 'pcsSales':
            this.$bvModal.show('incomePcsSalesDetailsModal');
            break;
          case 'hrsSales':
            this.$bvModal.show('incomeHrsSalesDetailsModal');
            break;
          case 'subscriptions':
            this.$bvModal.show('incomeSubscriptionsDetailsModal');
            break;
          default:
            break;
        }

        this.$store.dispatch('fetchRevenueList')
        
      } else {
        window.console.log('title error!')
      }

      this.loadData = false
    },

    modalClear() {
      this.revenueTitle = '';
      this.selected = 'onlyIncome';
    }

  }

}
</script>

<style lang="scss" scoped>
  .income-title {
    line-height: 90%; 
    margin-top: 2px; 
    margin-bottom: 0.5rem;
  }
  .income-option {
    padding: 0 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background: rgba(14, 187, 245, 0.10);
    }
    &.selected {
      background: rgba(14, 187, 245, 0.10);
    }
  }
</style>