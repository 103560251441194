<template>
  <div class="w-100 pl-3">

    <!-- Баннер нулевых данных  -->
    <div v-if="revenueList.length == 0"
      class="card mt-3 mb-3">
      <div class="bg-holder d-none d-lg-block bg-card" 
        style="background-image:url(/img/illustrations/corner-4.png);background-position: center; background-size: cover">
      </div>
      <!--/.bg-holder-->
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="mb-0">Выручка</h3>
            <div v-if="false" 
              class="btn btn-outline-light btn-sm fs--0 font-weight-light video-btn" >
              <i class="fas fa-play-circle mr-1"></i>
              Ознакомиться
            </div>
            <hr>
            <h5>Из чего складывается выручка?</h5> 
            
            <p class="mt-2 mb-0 text-800">
              Здесь необходимо добавить информацию о предполагаемых продажах своих основных продуктов, услуг или указать прочие источники выручки. 
              Постарайтесь сгруппировать их по какому-то объединяющиму признаку. 
              Например, если вы открываете фитнес-центр, то у вас источниками выручки могут быть: групповые абонементы, личные абонементы, личные тренировки и встречные продажи. 
              Обувной магазин может организовать выручки по направлениям продукции: кроссовоки, повседневная обувь, детская обувь и аксессуары.
              </p>
            <p v-if="introDescExpanded == false" 
              class="mt-2 mb-0 text-800">
              <a  
                data-toggle="collapse" 
                href="#readMoreAboutDirectCosts" 
                role="button" 
                aria-expanded="false" 
                aria-controls="readMoreAboutDirectCosts"
                @click="introDescExpanded = true">
                Подробнее ...
              </a>
            </p>
            <div class="collapse" id="readMoreAboutDirectCosts">
                <p class="mt-2 text-800">
                  Не делайте список слишком длинным, 3-5 позиций вполне достаточно. 
                  Попытка контролировать десятки отдельных потоков выручки может сделать финансовый прогноз перегруженным и тяжелым для понимания. 
                  Кроме того, помните, что в дальнейшем, на панели маниторинга, вы сможете сравнивать свои прогнозные значения с фактическими данными. 
                  Поэтому подумайте о том, какие категории имееют для вас наибольший смысл.
                </p>
                <p class="mt-2 text-800">
                  Обратите внимание, что гранты, краудфандинг, пожертвования и другие деньги, которые не связаны с собственным или заёмным капиталом, 
                  также отражаются здесь.
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Графики -->
    <div v-if="!revenueList.length == 0"
      class="card mb-3 mt-3 w-100">
      <div 
        class="card-body rounded-soft bg-gradient pt-0 pb-1 blue-green-chart-bg">
        <div class="card-title">Выручка</div>
        <div class="card-subtitle">
          {{activeBPlan.startYear + selectedYear}}
        </div>
        <div class="row mt-2">
          <div class="col-sm-8">
            <forecast-chart-light class="w-100"
              :chartData="tableData.selectedYearChartData">
            </forecast-chart-light>
          </div>
          <div class="col-sm-4 pl-4">
            <forecast-years-chart-light
              :chartData="tableData.yearsChartData">
            </forecast-years-chart-light>
          </div>
        </div>
      </div>            
    </div>

    <!-- Строка табличных данных -->
    <div v-if="!revenueList.length == 0"
      class="row w-100 m-0">
      <!-- Таблица с детализацией выручки по году -->
      <div class="col-sm-12 px-0">
        <div class="card mb-2 w-100">
          <div class="card-header d-flex justify-content-end py-2 pr-0">
            <div class="col-sm-auto d-flex flex-center fs--1 mt-1 mt-sm-0">
                           
            </div>
          </div>
  
          <div class="card-body p-0">
            <div class="table-responsive">
              <table-section-render 
                :tableData="tableData"
                @activeYearSelect="(year) => selectedYear = year" 
                @openToEdit="openToEdit" />
            </div>
          </div>
          <div class="card-footer p-0">
            <small class="ml-2 fs--2">

            </small>
          </div>
        </div>
      </div>
    </div>
    <!-- Конец таблицы -->

    <hr>
    <div class="d-flex align-content-between row">
      <div class="col-5">
        <button 
          class="btn btn-outline-primary font-weight-normal mr-2"
           v-b-modal.incomeCreateModal>
          Добавить выручку 
        </button>
      </div>
      <div class="col-7 text-right">
        <router-link to="/finance/direct-costs" 
          class="btn btn-outline-primary btn-sm ml-3"
          @click="print">
          Себестоимость
          <i class="fas fa-chevron-right"></i>
        </router-link>
      </div>
    </div>

    
    
    <income-pcs-sales-modal />
    <income-hrs-sales-modal />
    <income-subscriptions-modal />
    <income-only-details-modal />
    <income-create-modal />


    
  </div>
</template>

<script>
import moment from 'moment'
import numeral from 'numeral'
import ForecastChartLight from '@/components/app/charts/ForecastChartLight'
import ForecastYearsChartLight from '@/components/app/charts/ForecastYearsChartLight'
import IncomeCreateModal from '@/components/app/modals/IncomeCreateModal'
import IncomePcsSalesModal from '@/components/app/modals/IncomePcsSalesDetailsModal'
import IncomeHrsSalesModal from '@/components/app/modals/IncomeHrsSalesDetailsModal'
import IncomeSubscriptionsModal from '@/components/app/modals/IncomeSubscriptionsDetailsModal'
import IncomeOnlyDetailsModal from '@/components/app/modals/IncomeOnlyIncomeDetailsModal'

import TableSectionRender from '../../components/app/Tables/TableSectionRender';

export default {
  data: () => ({
    introDescExpanded: false,
    monthlyDisplay: false,
    selectedYear: 0,
  }),

  components: {
    ForecastChartLight,
    ForecastYearsChartLight,
    IncomeCreateModal,
    IncomePcsSalesModal,
    IncomeHrsSalesModal,
    IncomeSubscriptionsModal,
    IncomeOnlyDetailsModal,
    TableSectionRender
  },

  mounted() {
    this.$store.dispatch('fetchRevenueList')
  },

  computed: {
    revenueList() {
      return this.$store.getters.getRevenueList;
    },
    
    activeBPlan() {
      return this.$store.getters.getActiveBPlan ? this.$store.getters.getActiveBPlan : {}
    },


    tableData() {
      // Загружаем данные по активам из базы данных
      const list = this.revenueList;
      // Переменная для последующего вычисления итогов таблицы
      var tableRows = []
      // Переменная для вычисления информации для графика выбранного года
      var chartData = []
      // Переменная для вычисления информации для графика данных по годам
      var chartYearsData = []
      // Переменная для передачи форматированной таблицы из функции
      var payload = []

      /* 
        По каждой из позиции производим форматирование даных для отображения.
      */
      
      // Пеоеменая для хранения форматированных данных
      var formatedValues, yearData = []
      
      for (var i = 0; i < list.length; i++) {
        // Берем каждую позицию и производим ее форматирование
        formatedValues = []
        yearData = []
        for (var y = 0; y < Object.values(list[i].values).length; y++) {
          const values = Object.values(list[i].values)[y]
          /* 
            Если расмматриваемый год равен выбранному, то группируем данные
            по квартально и добавляем сумму по году
          */
          if (y == this.selectedYear) {
            formatedValues.push(this.$func.arrSum(values.slice(0, 3))) // 1 кв.
            formatedValues.push(this.$func.arrSum(values.slice(3, 6))) // 2 кв.
            formatedValues.push(this.$func.arrSum(values.slice(6, 9))) // 3 кв.
            formatedValues.push(this.$func.arrSum(values.slice(9, 12))) // 4 кв.
            formatedValues.push(this.$func.arrSum(values)) // Итого по году
            chartData.push(values) // Данные для графика выбранного активного года
          } else {
            formatedValues.push(this.$func.arrSum(values)) // Итого по году
          }
          
          // Собираем данные по годам
          yearData.push(this.$func.arrSum(values));
          
        }

        
                
        const data = {
          type: 'categoryItem',
          title: list[i].title,
          values: formatedValues
        }

        tableRows.push(formatedValues)
        payload.push(data)
        chartYearsData.push(yearData)
      }

      
      // Расчитываем итоговые значения футера таблицы
      var colsSum = this.$func.colSum(tableRows)
      
      // Расчитываем значения для графика по месяцам
      var selectedYearChartData = this.$func.colSum(chartData)
      // Расчитываем значения для графика по месяцам
      var yearsChartData = this.$func.colSum(chartYearsData)

      return { data: payload, total: colsSum, selectedYearChartData, yearsChartData }
    },

    
    

    chartData() { 
      var chartData = []
      
      if (this.selectedYear == 2020) {
        return {
          data: this.salesDataByMonthCalc(this.selectedYear),
          period: 'month'
        }
      }

      for (var year=2020; year < this.selectedYear + 1; year++) {
        chartData = chartData.concat(this.qDataCalc(this.salesDataByMonthCalc(year)))
      }
      return {
        data: chartData,
        period: 'quarter'
      } 
    },

    salesDataByYears() {
      var yearData = []
      var dataByYears = []
      for (var i=0; i < this.revenueList.length; i++) {
        yearData = Object.values(this.revenueList[i].incomes).map(value => {
          return this.yDataCalc(value)
        })
        dataByYears.push(yearData)
      }
      return dataByYears
    }
  },

  methods: {
    qDataCalc(monthData) {
      var data = []
      for (var i=0; i < 4; i++) {
        var sum = 0
        monthData.slice(i*3, (i+1)*3).forEach(item => {
          sum += item
        })
        data.push(sum)
      }

      return data
    },

    yDataCalc(monthData) {
      var data = 0
      monthData.forEach(item => {
        data += item
      })
      return data
    },
    
    // Расчитываем совокупные продажи по всем позициям в конкретном году
    salesDataByMonthCalc(year) {
      var colsSum = [0]
      for (var i=0; i < this.revenueList.length; i++) {
        colsSum = this.revenueList[i].incomes[year].map((value, index) => {
          if (!colsSum[index]) {
            return value
          } else {
            return colsSum[index] + value
          }
        })
      }

      return colsSum
    },

    monthName(monthNumber) {
      return moment.months(monthNumber)
    },
    
    print() {
      window.console.log(numeral(10000).format('(0.00a)'))
    }, 

    async openIncomeToEdit(incomeItem) {
      await this.$store.commit('setIncomeToEdit', incomeItem)
      
      switch (incomeItem.type) {
          case 'onlyIncome':
            this.$bvModal.show('incomeOnlyIncomeDetailsModal')
            break;
          case 'pcsSales':
            this.$bvModal.show('incomePcsSalesDetailsModal')
            break;
          case 'hrsSales':
            this.$bvModal.show('incomeHrsSalesDetailsModal')
            break;
          case 'subscriptions':
            this.$bvModal.show('incomeSubscriptionsDetailsModal')
            break;
          default:
            break;
        } 
    },

    async openToEdit(index) {
      const revenueItem = this.$store.getters.getRevenueList[index];
      await this.$store.commit('setRevenueToEdit', revenueItem)
      switch (revenueItem.type) {
        case 'onlyIncome':
          this.$bvModal.show('incomeOnlyIncomeDetailsModal')
          break;
        case 'pcsSales':
          this.$bvModal.show('incomePcsSalesDetailsModal')
          break;
        case 'hrsSales':
          this.$bvModal.show('incomeHrsSalesDetailsModal')
          break;
        case 'subscriptions':
          this.$bvModal.show('incomeSubscriptionsDetailsModal')
          break;
        default:
          break;
      }        
    },
  }
}
</script>

<style lang="scss" scoped>
thead, tfoot {
  font-family: 'Proxima Nova';
  background: #4d596961 !important;
}


.table-sm {
  td {
  height: 25px !important;
  line-height: 20px;
  padding: 2px !important;
  }
}


.title {
  height: 20px;
  &:hover {
    .edit-btn {
      display: inline;
    }
  }
  .edit-btn {
    color: rgba(145, 145, 145, 0.2);
    display: none;
    border: none !important;
    &:hover {
      color: rgb(145, 145, 145);
    }
  }
}

.bg-gradient {
  &.green {
    background-image: linear-gradient(-45deg, #0C271E, #17A674);
  }
  &.red {
    background-image: linear-gradient(-45deg, #b1010c, #db9e86);
  }
  &.neutral {
    background-image: linear-gradient(-45deg, #434a54, #8e959e);
  }
  .card-title {
    position: absolute;
    color: rgba(0,0,0,0.1);
    z-index: 0;
    font-size: 3.5rem;
    top: 0.5rem;
  } 
  .card-subtitle {
    position: absolute;
    color: rgba(0,0,0,0.1);
    z-index: 0;
    font-size: 4.5rem;
    font-weight: 600;
    top: 9rem;
    left: 46%;
    &.s2 {
      left: 3%;
    }
    &.s3 {
      left: 25%;
    }
  } 
  .graph-units {
    position: absolute;
    color: #ffffffb1;
    z-index: 0;
    font-size: 0.7rem;
    top: 0;
  }
}

.highcharts-background {
  background: #b1010c;
}

</style>