<template>
  <div class="d-flex align-items-center w-100 edit">
    <div class="btn btn-outline-dark btn-sm edit-btn mr-1 p-0 ml-2 mr-2"
      :class="{
        'd-inline text-800': titleEdit == true,
      }"
      @click="startEditing">
      <i class="fas fa-pencil-alt"></i>
    </div>
    <b-form-input 
      id="incomeTitle"
      class="border-0 font-weight-normal text-500 mb-0 mr-auto px-0"
      :class="{'text-lowercase': titleEdit == false}"
      style="font-family: 'Proxima Nova'; background: transparent; outline: none; min-width: 60%"
      type="text" 
      ref="titleEdit"
      :disabled="!titleEdit"
      v-model="incomeTitle" 
      @blur="titleEdit = false"
      @keydown.enter="titleEdit = false"/>  
  </div>
</template>

<script>
export default {
  props: ['title'],
  data: () => ({
    titleEdit: false,
  }),
  
  computed: {
    incomeTitle: {
      get: function () {
        return this.title
      },
      set: function (newValue) {
        this.$emit('updateTitle', newValue)
      }
    } 
     
  },
  
  methods: {
    startEditing() {
      this.titleEdit = true
      setTimeout(() => {
        this.$refs.titleEdit.focus()
      }, 100)
      
    },
  }
}
</script>

<style lang="scss" scoped>

.edit {
  &:hover {
    .edit-btn {
      display: inline;
    }
  }
  .edit-btn {
    color: rgba(145, 145, 145, 0.2);
    display: none;

  &:hover {
    color: rgb(145, 145, 145);
    }
  }
}

</style>