<template>
  <div>
    <b-modal
      id="incomeOnlyIncomeDetailsModal" 
      ref="incomeOnlyIncomeDetailsModal"
      header-class="pb-2"
      size="xl"
      v-model="showModal">

      <template slot="modal-header">
         <b-col sm="12" class="d-flex pr-1">
            <div class="d-flex col-6 col-sm-6 text-left ml-2">
              <h4 id="modalLabel" class="text-primary mr-2">
                Выручка
              </h4>
              <income-title-edit 
                :title="sectionData ? sectionData.title : ''" 
                @updateTitle="updateTitle" /> 
            </div>
            <div class="col-4 col-sm-5 ml-auto text-right pl-0 mr-1">
                <button 
                  type="button" 
                  aria-label="Close" 
                  class="close align-top ml-4 pr-0"
                  @click.prevent="showModal = false">
                  ×
                </button>
              </div> 
         </b-col>           
      </template>

      <!-- INCOME SECTION -->
      <wizard-section
        :data="sectionData ? sectionData.values : []" 
        unitsTitle="рублей"
        :defaultMax="100000"
        :decimals="0">
        <slot>
          <h5>Укажите плановую выручку</h5>
          <p class="font=proxima fs--1 mt-2">
            Вывбран вариант ввода значения выручки, а не расчетные показатели доступные в других вариантах. 
            Этот вариант наиболее простой и понятный, т.к. не требует точных расчетов. А возможно вы уже все расчитали (например в Excel). <br>
            Просто введите прогнозируемую сумму дохода ниже. 
          </p>
        </slot>
      </wizard-section>
      <!-- END INCOME SECTIN -->
      <!-- ----------------- -->

      <template v-slot:modal-footer>

        <div class="w-100">
          <div class="btn btn-outline-dark delete-btn ml-2 px-2 py-0 float-left"
            @click="deleteIncome">
            <i class="far fa-trash-alt mr-2 py-0"></i>
            <span class="font-proxima font-weight-light">
              Удалить
            </span>
          </div>
          
          <b-button 
            v-if="savingData"
            class="float-right"
            variant="outline-success"
            size="sm"
            disabled>
            <b-spinner small type="grow"></b-spinner>
            Сохраняем...
          </b-button>
          <b-button
            v-if="!savingData"
            variant="outline-success"
            size="sm"
            class="float-right"
            @click="saveData">
            Готово!
          </b-button>
        </div>

      </template>
    
    </b-modal>
  </div>
</template>

<script>
import WizardSection from '../widgets/Modals/IncomeCreate/WizardSection'
import IncomeTitleEdit from '../widgets/Modals/IncomeCreate/IncomeTitleEdit'

export default {
  props: ['docId'],
  data: () => ({
    showModal: false,
    activeYear: 0,
    savingData: false,
    titleEdit: false,
  }),
  
  components: {
    WizardSection,
    IncomeTitleEdit
  },

  computed: {
    sectionData() {
      return this.$store.getters.getRevenueToEdit
    }
  },

  methods: {
    updateTitle(value) {
      this.sectionData.title = value
    },

    updateOnEnter() {
      this.titleEdit = false
    },

    async saveData() {
      this.savingData = true

      const payload = {
        path: this.sectionData.path,
        title: this.sectionData.title,
        type: this.sectionData.type,
        values: this.sectionData.values,
      }

      await this.$store.dispatch('updateRevenue', payload)
      this.savingData = false
      this.showModal = false
    },

    deleteIncome() {
      this.$bvModal.msgBoxConfirm('Вы действительно хотите удалить данный источник выручки?', {
        title: 'Подтвердите удаление',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Да, удалить',
        cancelTitle: 'Нет',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch('deleteRevenue', this.sectionData)
          this.showModal = false
        }
      })
      .catch(err => {
          // An error occurred
          window.console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.delete-btn {
  color: rgba(145, 145, 145, 0.2);
  border: none;
  span {
    display: none;
  }
  &:hover {
    color: #e63757;
    border-color: rgb(145, 145, 145) !important;
    span {
      display: inline;
    }
  }
}
 
</style>